@import '../../assets/styling/variables';

.footer {
    &-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 5vh;
        font-family: '8bitfont';
        color: white;
        text-shadow: $text_shadow;
        a{
            color: white;
            text-decoration: none;
            &:visited {
                color: white;
            }
        }
    }
}