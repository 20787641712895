@import '../../../../assets/styling/variables';

.controls {
    &-wrapper {
        display: flex;
        flex-direction: row;
        font-family: '8bitfont';
        color: white;
        font-size: 1.3em;
        justify-content: space-around;
        width: 30vw;
        span {
            cursor: pointer;
            text-shadow: $text_shadow;
        }
    }
}