.gamegrid {
    &-wrapper {
        height: fit-content;
        margin-bottom: auto;
    }
    &-game {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &-controls {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 10vh;
    }
}