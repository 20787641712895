@import '../../../../../../assets/styling/variables';

.cell {
    &-wrapper {
        border: rgba(128, 128, 128, 0.05) 1px solid;
        aspect-ratio: 1;
    }
    &-active {
        background-color: white;
        box-shadow: $text_shadow;
    }
}