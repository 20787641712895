@import '../../../../assets/styling/variables';

.game {
    &-wrapper {
        border: white 3px solid;
        box-shadow: $text_shadow;
        height: 700px;
        width: 700px;
        display: grid;
        grid-template-columns: repeat(auto-fill, calc(700px * 0.025));
        grid-template-rows: repeat(auto-fill, calc(700px * 0.025));
        &::-webkit-scrollbar {
            display: none;
        }
    }
}